import * as React from "react";
import {Button, FormControl, InputLabel, MenuItem, Select, Stack} from '@mui/material'
import {useEffect, useState} from "react";
import _, { map } from "underscore"


export function PrinterSetup(props: {onPrinterChange: (printer: USBDevice) => any}) {
    const epsonVendorId = 0x04B8
    const selectedDeviceSerialNumberStorageKey = "selectedDeviceSerialNumber";
    const filters = [{vendorId: epsonVendorId}]
    const [selectedDevice, setSelectedDevice] = useState(null)
    const [pairedDevices, setPairedDevices] = useState(null)

    navigator.usb.addEventListener('connect', event => {
        getDevices()
    });

    navigator.usb.addEventListener('disconnect', event => {
        getDevices()
    });

    const onClick = async () => {
        // Trigger pairing dialog
        await navigator.usb.requestDevice({filters: filters})
        await getDevices()
    }

    async function getDevices() {
        // The browser will only list devices with getDevices() that the script origin already has access to, i.e. paired
        // devices.
        console.log("Getting devices")
        const devices = await navigator.usb.getDevices()
        if (devices.length > 0) {
            console.log("Got devices from browser")
            let availableDevices = devices.map((device) => { return device.serialNumber}).sort()
            let alreadyKnownDevices = pairedDevices?.map((device) => { return device.serialNumber}).sort()
            if (!_.isEqual(availableDevices, alreadyKnownDevices)) {
                console.log("Setting paired device")
                setPairedDevices(devices)
            }

            const device = devices.find(element => element.serialNumber == getDeviceSerialNumberFromStorage())
            // Device that was set as selected in the past is now in the list of available devices
            if (device) {
                console.log("Selected device is availablex")
                if (device.serialNumber != selectedDevice?.serialNumber) {
                    setSelectedDevice(device)
                }
                props.onPrinterChange(device)
            } else {
            // Selected device is not available
                console.log("Selected device not available")
                if (selectedDevice) {
                    setSelectedDevice(null)
                }
                props.onPrinterChange(null)
            }
        } else {
            setPairedDevices(null)
            if (selectedDevice) {
                setSelectedDevice(null)
            }
            props.onPrinterChange(null)
        }
    }

    useEffect(() => {
        console.log("Use effect")
        getDevices()
    })

    function getDeviceSerialNumberFromStorage() {
        return localStorage.getItem(selectedDeviceSerialNumberStorageKey)
    }

    function handleChange(event) {
        const serialNumber:string = event.target.value
        const device = pairedDevices.find(element => element.serialNumber == serialNumber)
        localStorage.setItem(selectedDeviceSerialNumberStorageKey, serialNumber)
        setSelectedDevice(device)
    }

    if (pairedDevices) {
        const selected = selectedDevice?.serialNumber || ''
        return (
            <Stack>
                <FormControl fullWidth>
                    <InputLabel>Drucker</InputLabel>
                    <Select onChange={handleChange} value={selected} id="printer" label="printer">
                        {pairedDevices.map((device) => (
                          <MenuItem key="sth" value={device.serialNumber}>{`${device.manufacturerName} - ${device.productName}`}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button variant="contained" onClick={onClick}>Weiteren Drucker hinzufügen</Button>
            </Stack>
        )
    } else {
        return <Button variant="contained" onClick={onClick}>Neuen Drucker hinzufügen</Button>
    }
}
