import * as React from "react";
import {Alert, Button, List, Snackbar, Stack} from "@mui/material";
import {go} from "../index";
import {PrinterService} from "../printer_service";

export class Printer extends React.Component<any, any> {
    private printerService: PrinterService

    constructor(props) {
        super(props);

        this.state = {showRestartSnackbar: false}
    }

    public render(){
        console.log("rendering printer")
        if (!(this.props.config.password && this.props.config.roomId && this.props.config.ticketFooter)) {
            this.stopPrinter()
            return <Alert severity="error">Konfiguration unvollständig, Drucker ist nicht gestartet.</Alert>
        }

        console.log(!!this.props.config.printer)
        if (!this.props.config.printer) {
            this.stopPrinter()
            return <Alert severity="error">Es wurde kein Drucker ausgewählt.</Alert>
        }

        this.startPrinter()

        return <Stack>
                 <Alert severity="success">Drucker gestartet.</Alert>
                 <Button onClick={this.onResetButtonClick}>Drucker neu starten</Button>
                 <Snackbar open={this.state.showRestartSnackbar} onClose={this.onCloseRestartSnackbar} autoHideDuration={3000}>
                     <Alert severity="info">Drucker wurde neu gestartet</Alert>
                 </Snackbar>
               </Stack>
    }

    private onCloseRestartSnackbar = () => {
        this.setState({...this.state, showRestartSnackbar: false})
    }

    private onResetButtonClick = () => {
        this.stopPrinter()
        this.startPrinter()
        this.setState({...this.state, showRestartSnackbar: true})
    }

    private async startPrinter() {
        if (!!this.printerService) return

        this.printerService = await go(this.props.config.password,this.props.config.ticketFooter, this.props.config.roomId, this.props.config.printer)
    }

    private stopPrinter = () => {
        this.printerService?.stop()
        this.printerService = null
    }
}
