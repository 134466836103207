import * as React from "react";
import {Config, Setup} from './setup'
import {Grid} from "@mui/material";
import {Printer} from "./printer";

export class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = new Config()
    }

    handleConfigChange = (config: Config) => {
        this.setState(config)
    }

    public render() {
        console.log(this.state)
        return (
            <Grid container spacing={2}>
                <Grid item xs={4} minWidth={500}>
                    <Setup config={this.state} handleConfigChange={this.handleConfigChange}/>
                    <Printer config={this.state} />
                </Grid>
            </Grid>
        )
    }
}
