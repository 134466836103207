import * as React from "react";
import {Button, TextField, Stack} from '@mui/material'
import {useEffect} from "react";

export function GenericStringStore(props: {storageKey: string, text: string, onStringChange: (string: string) => any}) {
    // The source of truth is always the local storage for this component. The internal in-memory state is also used
    // during unsaved edits, i.e. it does not necessarily contain the saved string.
    // The value from the local storage gets then propagated to the parent component via the onStringChange callback

    const [state, setValue] = React.useState( {editMode: false, value: localStorage.getItem(props.storageKey) || ''})

    const onChange = event => setValue({...state, value: event.target.value})
    const onSave = () => {
        localStorage.setItem(props.storageKey, state.value)
        props.onStringChange(state.value)
        exitEditMode()
    }
    const enterEditMode = () => setValue({...state, editMode: true})
    const exitEditMode = () => setValue({...state, editMode: false})
    const tokenStored = ():boolean => !!localStorage.getItem(props.storageKey)
    const showEditMode = ():boolean => !tokenStored() || state.editMode

    useEffect(() => {
        // Propagate local storage value to parent component on initial load of component
        if (!state.editMode) {
            props.onStringChange(state.value)
        }
    }, [])

    if (showEditMode()) {
        return <Stack direction={"row"}>
            <TextField fullWidth variant={"outlined"} required label={`${props.text}`} value={state.value} onChange={onChange}/>
            <Button variant="contained" onClick={onSave}>Speichern</Button>
        </Stack>
    } else {
        return <Stack direction={"row"}>
            <TextField fullWidth variant={"outlined"} required disabled={true} value={`${props.text} gespeichert`} />
            <Button variant="contained" onClick={enterEditMode}>Ändern</Button>
        </Stack>
    }
}
