import * as React from "react";
import {GenericStringStore} from './setup/GenericStringStore'
import {Card, CardContent, Stack, Typography} from "@mui/material";
import {PrinterSetup} from "./setup/printerSetup";

export class Setup extends React.Component<any, any> {

    // TODO: dry, i.e. refactor all handlers in something more generic
    handlePasswordChange = (password: string) => {
        let config = this.props.config
        config.password = password
        this.props.handleConfigChange(config)
    }

    handleRoomIdChange = (roomId: string) => {
        let config = this.props.config
        config.roomId = roomId
        this.props.handleConfigChange(config)
    }

    handlePrinterChange = (printer: USBDevice) => {
        let config = this.props.config
        console.log("handling printer change")
        if (config.printer?.serialNumber != printer?.serialNumber) {
            console.log("setting printer")
            config.printer = printer
            this.props.handleConfigChange(config)
        }
    }

    handleTicketFooterChange = (ticketFooter: string) => {
        let config = this.props.config
        config.ticketFooter = ticketFooter
        this.props.handleConfigChange(config)
    }


    render() {
        return(
                <Card variant={"outlined"}>
                    <CardContent>
                        <Stack spacing={2}>
                            <Typography variant="h5" component="div">
                                Konfiguration
                            </Typography>
                            <GenericStringStore onStringChange={this.handlePasswordChange} storageKey={'token'} text={'Druckerpasswort'} />
                            <GenericStringStore onStringChange={this.handleRoomIdChange} storageKey={'room'} text={'Serveradresse'} />
                            <GenericStringStore onStringChange={this.handleTicketFooterChange} storageKey={'ticketfooter'} text={'Text Ticket-Fußzeile'} />
                            <PrinterSetup onPrinterChange={this.handlePrinterChange} />
                        </Stack>
                    </CardContent>
                </Card>
        )
    }
}

export class Config {
    public password: string
    public roomId: string
    public ticketFooter: string
    public printer: USBDevice
}
